import { makeStyles } from "@mui/styles";

export const sectionCategoryStyles = makeStyles(() => ({
  section_menus__root: {},
  section_category__menu: {
    marginLeft: 16,
  },
  text_block__navigation_container: {
    position: "absolute",
    bottom: 32,
    left: "50%",
    transform: "translateX(-50%)",
  },
  section__navigation_container: {
    position: "absolute",
    bottom: 32,
    left: "50%",
    transform: "translateX(-50%)",
  },
}));
