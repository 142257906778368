import { forwardRef } from "react";

import { navigationStyles } from "./Navigation.styles";

export const Navigation = forwardRef((props, ref) => {
  const classes = navigationStyles();

  return (
    <div ref={ref} className={classes.navigationRoot}>
      {props.children}
    </div>
  );
});
