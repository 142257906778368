import { createSlice } from "@reduxjs/toolkit";
import { findScrollNavTarget } from "../utils/storedEventsFunctions";

const initialState = {
  eventSource: "",
  eventType: "",
  eventValue: "",
  state: "loading",

  storedEvents: {
    selectedLanguage: "",
    selectedCategory: "",
    selectedSubcategory: "",
    subcategoriesTopPositions: [],
  },
};

export const eventBrokerSlice = createSlice({
  name: "broker",
  initialState,

  reducers: {
    fireGenericEvent: (state, action) => {
      state.eventSource = action.payload.eventSource;
      state.eventType = action.payload.eventType;
      state.eventValue = action.payload.eventValue;
    },

    updateState: (state, action) => {
      state.state = action.payload;
      state.eventValue = "";
    },

    updateSelectedLanguage: (state, action) => {
      state.storedEvents.selectedLanguage = action.payload;
      state.eventValue = "";
    },

    updateSelectedCategory: (state, action) => {
      state.storedEvents.selectedCategory = action.payload;
      state.storedEvents.selectedSubcategory = "";
      state.eventValue = "";
    },

    updateSelectedSubcategory: (state, action) => {
      state.storedEvents.selectedSubcategory = action.payload;
      state.eventValue = "";
    },

    updateSubcategoriesTopPositions: (state, action) => {
      state.storedEvents.subcategoriesTopPositions = action.payload;
      state.eventValue = "";
    },

    updateSelectedMenuByScrollNavigation: (state, action) => {
      const direction = action.payload.direction || "next";

      const nextSubcategory = findScrollNavTarget(
        state.storedEvents,
        action.payload.subcategories,
        direction
      );

      if (nextSubcategory !== "outOfRange") {
        state.storedEvents.selectedSubcategory = nextSubcategory;
      }

      state.eventValue = "";
    },
  },
});

export const {
  fireGenericEvent,
  updateState,
  updateSelectedLanguage,
  updateSelectedCategory,
  updateSelectedSubcategory,
  updateSubcategoriesTopPositions,
  updateSelectedMenuByScrollNavigation,
} = eventBrokerSlice.actions;

export const listenEventBroker = (state) => state.eventBroker;

export const listenStoredEvents = (state) => state.eventBroker.storedEvents;

export default eventBrokerSlice.reducer;
