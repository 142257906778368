import { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";

import { Menu } from "features/UI/organisms/menu/Menu";
import { MenuItem } from "features/UI/molecules/menu-item/MenuItem";
import { ContentWrapper as ContentWrapperStyles } from "features/UI/molecules/content/wrapper/text-block/ContentWrapper";

import { TextBlock } from "features/UI/molecules/content/text-block/TextBlock";
import { findElementPositionById } from "features/utils/DOM/findElementPositionById";

import { sectionMenusStyles } from "./SectionMenus.styles";
import { UncategorizedContent } from "features/UI/atoms/content/uncategorized-content/UncategorizedContent";

export const SectionMenus = forwardRef((props, ref) => {
  const classes = sectionMenusStyles();

  const { categories, selectedCategory, subcategories, uncategorizedContent } =
    props;

  const [showSubcategoriesAsMenu, showSubcategoriesAsMenuSet] = useState(true);

  useEffect(() => {
    const currentSelectedCategory = categories.find(
      (el) => el.id === selectedCategory
    );

    if (currentSelectedCategory) {
      showSubcategoriesAsMenuSet(
        currentSelectedCategory.showSubcategoriesAsMenu
      );
    }
  }, [selectedCategory]);

  const subcategoryMenuTopPositioning = () => {
    if (!selectedCategory) return 0;

    const categoryMenuItemPosition = findElementPositionById(
      `menuItem-category-${selectedCategory}`
    );

    if (!categoryMenuItemPosition) return 0;

    const menuItemTop = categoryMenuItemPosition.top - 50;

    if(menuItemTop < 0){
      return 0
    } else {
      return menuItemTop;
    }
  };

  return (
    <div
      className={clsx(classes.section_menus__root)}
      id="section-menus"
      ref={ref}
    >
      <Menu compact className={classes.section_menus__categories_container}>
        {categories &&
          categories.map((category) => (
            <div
              key={`menuItem-${category.id}`}
              className={`menuItem-${category.id}`}
            >
              <MenuItem variant="category" id={category.id}>
                {category.title}
              </MenuItem>
            </div>
          ))}
      </Menu>
      {selectedCategory === '' ? (
        <UncategorizedContent>
          {uncategorizedContent.content}
        </UncategorizedContent>
      ) : (
        subcategories.length > 0 &&
        (showSubcategoriesAsMenu ? (
          <div
            style={{
              marginTop: subcategoryMenuTopPositioning()
            }}
          >
            <Menu className={classes.section_subcategory__menu}>
              {subcategories &&
                subcategories.map((subcategory) => (
                  <MenuItem variant="subcategory" id={subcategory.id}>
                    {subcategory.title}
                  </MenuItem>
                ))}
            </Menu>
          </div>
        ) : (
          <ContentWrapperStyles smartphone>
            <div
              style={{
                marginTop: subcategoryMenuTopPositioning()
              }}
              dangerouslySetInnerHTML={{
                __html: subcategories[0].content
              }}
            ></div>
          </ContentWrapperStyles>
        ))
      )}
    </div>
  );
});
