import { useSelector } from "react-redux";
import { listenStoredEvents } from "features/event-driver/broker/eventBrokerSlice";

import { MenuItemCategory } from "features/UI/atoms/menu/category/MenuItemCategory";
import { MenuItemSubcategory } from "features/UI/atoms/menu/subcategory/MenuItemSubcategory";

import { menuItemStyles } from "./MenuItem.styles";

export function MenuItem(props) {
  const classes = menuItemStyles();

  const { id, variant, children } = props;

  const scrollEffectsPurposeId = `menuItem-${variant}-${id}`;

  const storedEvents = useSelector(listenStoredEvents);
  const selectedCategory = storedEvents.selectedCategory;

  return (
    <div
      role="tab"
      className={classes.menu_item__root}
      id={scrollEffectsPurposeId}
      key={scrollEffectsPurposeId}
    >
      {variant === "category" && (
        <MenuItemCategory id={id}>{children}</MenuItemCategory>
      )}
      {variant === "subcategory" && selectedCategory && (
        <MenuItemSubcategory id={id}>{children}</MenuItemSubcategory>
      )}
    </div>
  );
}
