import { fetchAPI } from "features/utils/strapi/api";
import { getStrapiMedia } from "./media";

export async function getAllLocaleEntries(lang) {
  if(!lang) return;
  
  let dataForTheStore = {
    categories: "",
    subcategories: "",
    subcategoriesContent: "",
    uncategorizedContent: "",
    global: "",
    homepage: "",
    languages: "",
  };

  await getCategories(lang).then((res) => {
    dataForTheStore = {
      ...dataForTheStore,
      categories: res.map((el) => {
        return {
          ...el.attributes,
          id: el.id,
        };
      }),
    };
  });

  await getSubcategories(lang).then((res) => {
    dataForTheStore = {
      ...dataForTheStore,
      subcategories: res.map((el) => {
        return {
          id: el.id,
          category: el.attributes.category.data.id,
          title: el.attributes.title,
          content: sanitizeContent(el.attributes.content),
          slug: el.attributes.slug,
          alias_slug_de: el.attributes.alias_slug_de,
          alias_slug_fr: el.attributes.alias_slug_fr,
          alias_slug_en: el.attributes.alias_slug_en
        };
      }),
      subcategoriesContent: populateSubcategoriesContent(res),
    };
  });
  
  await getUncategorizedContent(lang).then((res) => {
    dataForTheStore = {
      ...dataForTheStore,
      uncategorizedContent: res.attributes
    };
  });
  
  await getGlobal(lang).then((res) => {
    dataForTheStore = {
      ...dataForTheStore,
      global: res.attributes
    };
  });
  
  await getHomepage(lang).then((res) => {
    dataForTheStore = {
      ...dataForTheStore,
      homepage: res.attributes
    };
  });
  
  await getLanguageControl().then((res) => {
    dataForTheStore = {
      ...dataForTheStore,
      languages: res.attributes.language
    };
  });

  return dataForTheStore;
}

function sanitizeContent(content) {
  //Add cms endpoint to src
  const formated = content.replaceAll(
    '/uploads',
    'https://om-cms.stopplay.app/uploads'
  );
  const imgWithSpecialMargin = formated.replace(/<\/p><p><img /g, '</p><p><img style="margin-top: 25px"')
  const replacedImgOpacity = imgWithSpecialMargin.replace(/<p><img/g, '<p style="opacity: 1"><img')
  const formatContactAddress = replacedImgOpacity.replaceAll(/&lt;br&gt;/g, '<br>')
  
  return formatContactAddress;
}

function populateSubcategoriesContent(subcategories) {
  const tempObj = [];
  subcategories.map((sub, i) => {
    tempObj.push({
      id: i,
      subcategory: sub.id,
      content: sanitizeContent(sub.attributes.content),
    });
    return;
  });

  return tempObj;
}

export async function getCategories(lang) {
  const [categories] = await Promise.all([
    fetchAPI("/categories/", {
      locale: lang,
      populate: ["image"],
    }),
  ]);

  return categories.data;
}

export async function getSubcategories(lang) {
  const [subcategories] = await Promise.all([
    fetchAPI("/subcategories/", {
      locale: lang,
      populate: ["category"],
    }),
  ]);
  
  return subcategories.data;
}

export async function getUncategorizedContent(lang) {
  const [content] = await Promise.all([
    fetchAPI("/uncategorized-content/", {
      locale: lang,
    }),
  ]);

  return content.data;
}

export async function getGlobal(lang) {
  const [global] = await Promise.all([
    fetchAPI("/global/", {
      locale: lang,
      populate: ["blob"],
    }),
  ]);

  return global.data;
}

export async function getHomepage() {
  const [global] = await Promise.all([
    fetchAPI("/homepage/", {
      populate: {
        carousel: {
          populate: '*',
        },
      },
    }),
  ]);

  return global.data;
}

export async function getLanguageControl() {
  const [language] = await Promise.all([
    fetchAPI("/languages-control/", {
      populate: ["language"],
    }),
  ]);

  return language.data;
}
