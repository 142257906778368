import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { Footer } from 'features/UI/organisms/footer/Footer';

import { Navigation } from 'features/UI/organisms/navigation/Navigation';
import { SectionMenus } from 'features/UI/organisms/tablet-sections/menus/SectionMenus';
import { ContentConstructor } from 'features/UI/organisms/content/content-constructor-desktop/ContentConstructor';
import { Previous } from 'features/UI/molecules/navigation/buttons/previous/Previous';
import { ContentWrapper as ContentWrapperStyles } from 'features/UI/molecules/content/wrapper/text-block/ContentWrapper';

import { updateCurrentPage } from 'features/event-driver/navigation/navigationSlice';
import {
  startTouch,
  detectMoveTouchDirection
} from 'features/utils/events/touchEvents';
import {
  updateSelectedCategory,
  updateSelectedSubcategory
} from 'features/event-driver/broker/eventBrokerSlice';

import { tabletContentStyles } from './TabletContent.styles';

export function TabletContent({ cmsData }) {
  const classes = tabletContentStyles();

  const dispatch = useDispatch();

  const {
    selectedCategory,
    selectedSubcategory,
    categories,
    subcategories,
    uncategorizedContent
  } = cmsData;

  const [sectionSlide, sectionSlideSet] = useState(0);

  function onClickPrevButton() {
    const rootEl = document.getElementById('tablet-content-root');
    document.body.style.overflow = 'hidden';
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: "smooth",
    // });

    // rootEl.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: "smooth",
    // });

    setTimeout(() => updateStore(), 300);
  }

  function updateStore() {
    if (selectedSubcategory) {
      dispatch(updateSelectedSubcategory(''));
      return;
    }
    dispatch(updateSelectedCategory(''));
    dispatch(updateSelectedSubcategory(''));
    dispatch(updateCurrentPage('home'));
    return;
  }

  const [carouselComposition, carouselCompositionSet] = useState([]);

  useEffect(() => {
    composeCarousel();
    slideHasChange();
  }, [selectedCategory, selectedSubcategory, sectionSlide, subcategories]);

  function composeCarousel() {
    const firstSection = (
      <SectionMenus
        categories={categories}
        selectedCategory={selectedCategory}
        subcategories={subcategories}
        selectedSubcategory={selectedSubcategory}
        uncategorizedContent={uncategorizedContent}
      />
    );

    carouselCompositionSet([firstSection]);
  }

  function slideHasChange() {
    const section = selectedSubcategory ? 3 : 2;

    sectionSlideSet(section);

    const rootEl = document.getElementById('tablet-content-root');

    if (section === 3) {
      // rootEl.style.overflow = "scroll";
      rootEl.ontouchstart = (e) => startTouch(e.touches[0]);
      rootEl.ontouchmove = (e) => handleTouchMove(e);
    } else {
      document.body.style.marginBottom = 'unset';
      // rootEl.style.overflow = "hidden";
      rootEl.ontouchstart = '';
      rootEl.ontouchmove = '';
    }
  }

  function handleTouchMove(e) {
    const rootEl = document.getElementById('tablet-content-root');

    const moveDirection = detectMoveTouchDirection(e.changedTouches[0]);
    if (moveDirection === 'right') {
      onClickPrevButton();
    }
  }

  function handleSwipeEnd(e) {
    const direction = detectMoveTouchDirection(e.changedTouches[0]);

    /*
      If you're on the last slide and swipe right
    */
    if (direction === 'right') {
      if (sectionSlide - 1 === -1) {
        const previousButtonElement =
          document.getElementById('previous-button');

        previousButtonElement.click();
      }
    }
  }

  return (
    <>
      <div
        className={classes.tablet_content__root}
        id="tablet-content-root"
        onTouchStart={(e) => startTouch(e.touches[0])}
        onTouchMove={(e) => handleTouchMove(e)}
      >
        <div
          className={
            selectedSubcategory
              ? classes.tablet_content__partial_carousel
              : classes.tablet_content__carousel
          }
        >
          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            selectedItem={sectionSlide}
            onSwipeStart={(e) => startTouch(e.targetTouches[0])}
            onSwipeEnd={handleSwipeEnd}
            onChange={(slide) => slideHasChange(slide)}
          >
            {carouselComposition.map((slide, index) => {
              return <React.Fragment key={index}>{slide}</React.Fragment>;
            })}
          </Carousel>
        </div>
        {selectedSubcategory && (
          <div
            className={classes.tablet_content__content_constructor_container}
          >
            <ContentWrapperStyles>
              <ContentConstructor tablet />
            </ContentWrapperStyles>
          </div>
        )}

        <div className={classes.tablet_content__navigation_container}>
          <Navigation>
            <Previous onClick={onClickPrevButton} />
          </Navigation>
        </div>
      </div>

      <Footer />
    </>
  );
}
