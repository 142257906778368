import { createSlice } from '@reduxjs/toolkit';
// import {
//   mockedCategories,
//   mockedSubcategories,
//   mockedSubcategoriesContent,
// } from "design-spec/mock";

const initialState = {
  // storedData: {
  //   categories: mockedCategories,
  //   subcategories: mockedSubcategories,
  //   subcategoriesContent: mockedSubcategoriesContent,
  // },
  storedData: {}
};

export const dataStoreSlice = createSlice({
  name: 'dataStore',
  initialState,

  reducers: {
    updateStore: (state, action) => {
      if (action.payload.storedData) {
        state.storedData = action.payload.storedData;
      }
    },
    clearStoredData: (state) => {
      state.storedData = initialState.storedData;
    }
  }
});

export const { updateStore, clearStoredData } = dataStoreSlice.actions;

export const listenDataStore = (state) => state.dataStore.storedData;

export default dataStoreSlice.reducer;
