import { useEffect, useState } from "react";

export const useRouterFinder = (renderControl) => {
  const [resolvedPath, resolvedPathSet] = useState({
    section: "",
    path: "",
    language: "",
    category: "",
    subcategory: ""
  })

  useEffect(() => {
    if (window.location) {
      let path = window.location.pathname.split("/");
      path = path.filter((el) => el !== "")

      if (path.length === 1 && path[1] !== "") {
        //path example: /de
        resolvedPathSet({
          ...resolvedPath,
          section: "home",
          path: path[0],
          language: path[0]
        })
      } else if (path.length === 2) {
        //path example: /de/offers
        resolvedPathSet({
          ...resolvedPath,
          section: "intro/categories",
          path: `${path[0]}/${path[1]}`,
          language: path[0],
          category: path[1],
        })
      } else if (path.length === 3) {
        //path example: /de/offers/anything
        resolvedPathSet({
          ...resolvedPath,
          section: "subcategories",
          path: `${path[0]}/${path[1]}/${path[2]}`,
          language: path[0],
          category: path[1],
          subcategory: path[2],
        })
      }
    }

  },[renderControl]);
  return resolvedPath
};
