import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  listenEventBroker,
  listenStoredEvents,
  updateSelectedSubcategory
} from 'features/event-driver/broker/eventBrokerSlice';

import { Fade, Typography } from '@mui/material';

import { menuItemSubcategoryStyles } from './MenuItemSubcategory.styles';
import { updateSmartphoneSection } from 'features/event-driver/navigation/navigationSlice';

import THEME from 'mui/theme-tokens.json';
const token_textsTimeout = THEME.transitions.fade.textsTimeout;

export function MenuItemSubcategory({ id, children }) {
  const classes = menuItemSubcategoryStyles();
  const dispatch = useDispatch();

  const eventBroker = useSelector(listenEventBroker);
  const storedEvents = useSelector(listenStoredEvents);
  const selectedSubcategory = storedEvents.selectedSubcategory;

  const [visible, visibleSet] = useState(true);
  const [textsTimeout] = useState(token_textsTimeout);

  useEffect(() => {
    visibilityToogle();
  }, [eventBroker.eventValue]);

  const visibilityToogle = () => {
    if (eventBroker.eventSource === 'MenuItem') {
      if (eventBroker.eventType === 'hover') {
        if (eventBroker.eventValue !== '') {
          visibleSet(false);
          return;
        }
      }
    }
    visibleSet(true);
    return;
  };

  const fireClickEvent = () => {
    dispatch(updateSelectedSubcategory(id));
    dispatch(updateSmartphoneSection('content'));

    return;
  };

  return (
    <Fade timeout={textsTimeout} in={visible}>
      <div
        onClick={() => fireClickEvent()}
        className={
          selectedSubcategory === id ? classes.menu_item__active : null
        }
      >
        <Typography
          variant="h1"
          component="p"
          dangerouslySetInnerHTML={{
            __html: children
          }}
        />
      </div>
    </Fade>
  );
}
