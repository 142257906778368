import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { selectCurrentPage } from 'features/event-driver/navigation/navigationSlice';
import {
  updateSelectedCategory,
  updateSelectedSubcategory
} from 'features/event-driver/broker/eventBrokerSlice';
import {
  updateCurrentPage,
  updateSmartphoneSection
} from 'features/event-driver/navigation/navigationSlice';

import { Fade, useMediaQuery, useTheme } from '@mui/material';

import { LogoSmall } from './logo-small/LogoSmall';
import { LogoDefault } from './logo-default/LogoDefault';

import { logoStyles } from './Logo.styles';

export function Logo(props) {

  const { isLoading, fadeOutContent } = props;

  const currentPage = useSelector(selectCurrentPage);

  const theme = useTheme();
  const mobileViewport = useMediaQuery(theme.breakpoints.down('desktop'));

  const classes = logoStyles();

  const dispatch = useDispatch();

  function updateEventStore() {
    dispatch(updateSelectedCategory(''));
    dispatch(updateSelectedSubcategory(''));
    dispatch(updateCurrentPage('home'));
    dispatch(updateSmartphoneSection('home'));
  }

  const handleTimeout = () => {
    if ((!isLoading && currentPage === 'home') || !mobileViewport || fadeOutContent) {
      return 900;
    }
    
    return 0
  }

  return (
    <div className={classes.logo__root} onClick={() => updateEventStore()}>
      <Fade
        in={
          isLoading ||
          Boolean(currentPage === 'content' && !mobileViewport) ||
          fadeOutContent
        }
        appear={false}
        timeout={900}
      >
        <LogoSmall className={classes.logo__svg} />
      </Fade>
      <Fade
        in={!isLoading && currentPage === 'home' && !fadeOutContent}
        appear={false}
        timeout={handleTimeout()}
      >
        <LogoDefault className={classes.logo__svg} />
      </Fade>
    </div>
  );
}
