import { makeStyles } from '@mui/styles';

export const languageSelectorStyles = makeStyles((theme) => ({
  language_selector__root: {
    position: 'fixed',
    inset: '0 0 auto auto',
    height: 100,
    zIndex: 99,
    display: 'flex',
    justifyContent: 'flex-end',

    opacity: '0',
    pointerEvents: 'none',

    transition: 'opacity 2s ease-in-out',

    padding: '10px 2rem',

    '&[data-fade-in="true"]': {
      opacity: '1'
    },
    '&[data-open="true"]': {
      pointerEvents: 'all',

      '& span': {
        transform: 'translateX(0)'
      }
    },
    '& span': {
      cursor: 'default',
      marginRight: 20,
      color: theme.palette.primary.main,
      transition: 'all ease 0.5s',
      zIndex: 1,
      height: 'fit-content',

      // '&:nth-child(2)': {
      //   transform: 'translateX(4.6rem)'
      // },
      // '&:nth-child(3)': {
      //   transform: 'translateX(2.3rem)'
      // },
      '&:last-child': {
        marginRight: 0
      }
    },
    [theme.breakpoints.up('desktop')]: {
      paddingRight: 30
    }
  },

  language_selector__state_initial: {
    opacity: 0,
    pointerEvents: 'unset',

    '&:last-child': {
      cursor: 'pointer',
      pointerEvents: 'auto',
      opacity: 1
    }
  },

  language_selector__state_select: {
    opacity: 1,
    cursor: 'pointer !important',
    pointerEvents: 'auto',

    '&:last-child': {
      color: theme.palette.primary.dark
    }
  },

  ls_background: {
    background:
      ' linear-gradient(180deg, #F1EBE7 0%, #F1EBE7 33.85%, rgba(241, 235, 231, 0) 100%)',

    position: 'absolute',
    inset: '0',

    opacity: '0',

    pointerEvents: 'none',

    transition: 'all .5s ease-in-out',

    [theme.breakpoints.down('desktop')]: {
      '&[data-open="true"]': {
        opacity: '1',
        pointerEvents: 'all'
      }
    }
  }
}));
