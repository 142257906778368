import { useEffect, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { Fade } from "@mui/material";

import { listenEventBroker } from "features/event-driver/broker/eventBrokerSlice";

import { uncategorizedContentStyles } from "./UncategorizedContent.styles";

import THEME from "mui/theme-tokens.json";

const token_textsTimeout = THEME.transitions.fade.textsTimeout;

export const UncategorizedContent = forwardRef((props, ref) => {
  const classes = uncategorizedContentStyles();

  const eventBroker = useSelector(listenEventBroker);

  const smartphone = props.smartphone || null

  const [textsTimeout] = useState(token_textsTimeout);
  const [visible, visibleSet] = useState(true);

  useEffect(() => {
    visibilityToogle();
  }, [eventBroker]);

  const visibilityToogle = () => {
    if (shouldHideThisItem()) {
      visibleSet(false);
      return;
    }
    visibleSet(true);
    return;
  };

  const shouldHideThisItem = () => {
    if (
      eventBroker.eventSource === "MenuItem" &&
      eventBroker.eventType === "hover" &&
      eventBroker.eventValue !== ""
    ) {
      return true;
    }
    return false;
  };

  return (
    <Fade timeout={textsTimeout} in={visible} ref={ref}>
      <div
        className={clsx(
          classes.uncategorized_content__root,
          props.large ? classes.uncategorized_content__large : null,
          props.smartphone ? classes.uncategorized_content__smartphone : null,
        )}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: props.children,
          }}
        ></div>
      </div>
    </Fade>
  );
});
