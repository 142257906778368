import { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';

import { Menu } from 'features/UI/organisms/menu/Menu';
import { MenuItem } from 'features/UI/molecules/menu-item/MenuItem';
import { ContentWrapper as ContentWrapperStyles } from 'features/UI/molecules/content/wrapper/text-block/ContentWrapper';

import { SectionTitle } from '../title/SectionTitle';

import { sectionSubcategoryStyles } from './SectionSubcategory.styles';
import { CategoryContent } from 'features/UI/molecules/content/category-content/CategoryContent';

export const SectionSubcategory = forwardRef((props, ref) => {
  const classes = sectionSubcategoryStyles();

  const {
    categories,
    selectedCategory,
    subcategories,
    sectionSlide,
    onClickPrevButton
  } = props;

  const sectionTitle = categories?.find(
    (category) => category.id === selectedCategory
  );

  const [showSubcategoriesAsMenu, showSubcategoriesAsMenuSet] = useState(true);

  useEffect(() => {
    const currentSelectedCategory = categories.find(
      (el) => el.id === selectedCategory
    );

    if (currentSelectedCategory) {
      showSubcategoriesAsMenuSet(
        currentSelectedCategory.showSubcategoriesAsMenu
      );
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (subcategories.length > 0 && sectionSlide === 2) {
      if (!showSubcategoriesAsMenu) {
        const rootEl = document.getElementById('smartphone-content-root');
        const carouselEl = document.querySelector('.carousel-root');
        props.enableScroll(rootEl, carouselEl);
      }
    }
  }, [subcategories.length, subcategories, sectionSlide]);

  return (
    <div
      className={clsx(classes.section_subcategory__root)}
      ref={ref}
      id="subcategory-root"
    >
      <SectionTitle onClickPrevButton={onClickPrevButton}>
        {sectionTitle?.title}
      </SectionTitle>

      {subcategories.length > 0 ? (
        showSubcategoriesAsMenu ? (
          <Menu className={classes.section_subcategory__menu}>
            {subcategories &&
              subcategories.map((subcategory, index) => (
                <MenuItem key={index} variant="subcategory" id={subcategory.id}>
                  {subcategory.title}
                </MenuItem>
              ))}
          </Menu>
        ) : (
          <CategoryContent>
            <div
              dangerouslySetInnerHTML={{
                __html: subcategories[0].content
              }}
            />
          </CategoryContent>
        )
      ) : null}
    </div>
  );
});
