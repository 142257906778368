import { makeStyles } from "@mui/styles";
import theme from "mui/theme";

export const contentStyles = makeStyles(() => ({
  content__root: {
    height: "100vh",
    backgroundColor: theme.palette.secondary.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.only('smartphone')]: {
      overflow: 'hidden',
    }
  },
}));
