import { makeStyles } from '@mui/styles';

export const logoStyles = makeStyles((theme) => ({
  logo__root: {
    cursor: 'pointer',

    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,

    [theme.breakpoints.up('desktop')]: {
      pointerEvents: 'all !important'
    }
  },
  logo__svg: {
    position: 'absolute',
    left: 0

    // opacity: 0,

    // pointerEvents: 'none',

    // transition: 'all .2s ease-in-out',

    // '&[data-fade-in="true"]': {
    //   opacity: 1,
    //   pointerEvents: 'all'
    // }
  }
}));
