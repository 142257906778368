import { forwardRef } from "react";

import { Typography } from "@mui/material";

import { sectionTitleStyles } from "./SectionTitle.styles";


export const SectionTitle = forwardRef((props, ref) => {
  const classes = sectionTitleStyles();
  
  return (
    <div ref={ref} className={classes.section_title__root}>
      <Typography variant="caption" className={classes.section_title__text} onClick={props.onClickPrevButton}>
        {props.children}
      </Typography>
    </div>
  );
});
