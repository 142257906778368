import { mockedCategories, mockedSubcategories } from 'design-spec/mock';

export function findScrollNavTarget(
  storedEvents,
  subcategories,
  direction = 'next'
) {
  const selectedCategory = storedEvents.selectedCategory;
  const selectedSubcategory = storedEvents.selectedSubcategory;

  const selectedCategorySubs = subcategories.filter(
    (el) => el.category === selectedCategory
  );

  const subcategoriesListSize = selectedCategorySubs.length;

  const selectedSubcategoryIndex = selectedCategorySubs.findIndex(
    (el) => el.id === selectedSubcategory
  );

  const outOfRangeSubcategoryIndex =
    direction === 'next'
      ? Number(selectedSubcategoryIndex) + 2
      : Number(selectedSubcategoryIndex) - 1;

  if (
    outOfRangeSubcategoryIndex > subcategoriesListSize ||
    outOfRangeSubcategoryIndex < 0
  ) {
    return 'outOfRange';
  }

  let actualNextSubcategoryIndex;

  if (direction === 'next') {
    actualNextSubcategoryIndex = subcategories.find(
      (entry) =>
        entry.id > selectedSubcategory && entry.category === selectedCategory
    ).id;
  } else {
    actualNextSubcategoryIndex = subcategories
      .slice()
      .reverse()
      .find(
        (entry) =>
          entry.id < selectedSubcategory && entry.category === selectedCategory
      ).id;
  }

  return actualNextSubcategoryIndex;
}
