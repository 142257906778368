import { useState, useEffect } from 'react';

import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

import { useCookiesBannerStyles } from './cookiesBanner.styles';
import { CloseIcon } from './close-icon/CloseIcon';

export const CookiesBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  const classes = useCookiesBannerStyles();

  const returnBannerText = () => {
    const prefix = window.location.pathname;

    if (prefix.includes('/en')) {
      return 'This site uses cookies to provide you with the best possible user experience.';
    } else if (prefix.includes('/fr')) {
      return 'Ce site utilise des cookies pour vous offrir la meilleure expérience utilisateur possible.';
    } else {
      return `Diese Website verwendet cookies, um Ihnen die bestmögliche Nutzererfahrung zu bieten.`;
    }
  };

  const handleClose = () => {
    localStorage.setItem('hasAcceptedCookies', true);
    setShowBanner(false);
  };

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('hasAcceptedCookies');

    if (!hasAcceptedCookies) {
      setShowBanner(true);
    }
  }, []);

  return (
    <Fade in={showBanner} timeout={900}>
      <div className={classes.cookies_banner}>
        <div className={classes.cb_backdrop} onClick={handleClose} />
        <div className={classes.cb_content}>
          <Typography
            variant="caption"
            component="p"
            color="primary.dark"
            className={classes.cb_c__text}
          >
            {returnBannerText()}
          </Typography>

          <button className={classes.cb_button} onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
      </div>
    </Fade>
  );
};
