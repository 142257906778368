import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { listenDataStore } from "features/event-driver/store/dataStoreSlice";

import { Typography } from "@mui/material";

import { ReactComponent as BlobAnimation } from "./assets/blob-animation.svg";
import { blobStyles } from "./Blob.styles";

export function Blob() {
  const classes = blobStyles();

  const storedData = useSelector(listenDataStore);
  const [blobContent, blobContentSet] = useState("");

  useEffect(() => {
    if (storedData) {
      if (storedData.global) {
        blobContentSet(storedData.global.blob);
      }
    }
  }, [storedData]);

  useEffect(() => {
    document.getElementById("blob-root").style.scale = blobContent.scale || 1;
  }, [blobContent]);

  return (
    <div className={classes.blob__root}>
      <div className={classes.blob__button}>
        <a target="_blank" rel="nofollow" href={`${blobContent.url}`}>
          <div className={classes.blob__text_container}>
            <Typography variant="body1">{blobContent.content}</Typography>
          </div>

          <BlobAnimation title="animated blob" id="blob-root" />
        </a>
      </div>
    </div>
  );
}
