import { useState, forwardRef, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { listenEventBroker } from 'features/event-driver/broker/eventBrokerSlice';

import { useCountUp } from 'react-countup';

import Typography from '@mui/material/Typography';

import { useLoadingCounterStyles } from './loadingCounter.styles';

export const LoadingCounter = forwardRef((props, ref) => {
  const [isCounterPaused, setIsCounterPaused] = useState(false);

  const { state: loadState } = useSelector(listenEventBroker);

  const classes = useLoadingCounterStyles();

  const { pauseResume } = useCountUp({
    ref: 'counter',
    start: 0,
    end: 100,
    duration: 2,
    useEasing: true,
    suffix: '%',
  });

  let timeoutId;
  useEffect(() => {
    timeoutId = setTimeout(() => {
      setIsCounterPaused(true);
      pauseResume();
    }, 333);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (loadState === 'idle' && isCounterPaused) {
      setIsCounterPaused(false);
      pauseResume();
    }
  }, [loadState, isCounterPaused]);

  return (
    <Typography
      id="counter"
      ref={ref}
      variant="body1"
      className={classes.loading_counter}
    />
  );
});
