import { useState, useEffect } from 'react';

import Fade from '@mui/material/Fade';

import { Logo } from './logo/Logo';
import { LoadingCounter } from './loading-counter/LoadingCounter';

import { useAppOverlayStyles } from './appOverlay.styles';

export const AppOverlay = (props) => {
  const classes = useAppOverlayStyles();
  const [renderLoading, setRenderLoading] = useState(true);

  const { isLoading } = props;

  let timeoutId;
  useEffect(() => {
    if (!isLoading) {
      timeoutId = setTimeout(() => {
        setRenderLoading(false);
      }, 3000);
    }

    if (isLoading && !renderLoading) {
      setRenderLoading(true);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isLoading]);

  return (
    <div className={classes.app_overlay}>
      <Logo {...props} />
      {renderLoading && (
        <Fade in={isLoading} timeout={2000}>
          <div>
            <LoadingCounter {...props} />
          </div>
        </Fade>
      )}
    </div>
  );
};
