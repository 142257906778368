import { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";

import { Menu } from "features/UI/organisms/menu/Menu";
import { MenuItem } from "features/UI/molecules/menu-item/MenuItem";

import { SectionTitle } from "../title/SectionTitle";
import { Navigation } from "../../navigation/Navigation";
import { Previous } from "features/UI/molecules/navigation/buttons/previous/Previous";
import { Next } from "features/UI/molecules/navigation/buttons/next/Next";

import { sectionCategoryStyles } from "./SectionCategory.styles";
import { useSelector } from "react-redux";
import { listenDataStore } from "features/event-driver/store/dataStoreSlice";

export const SectionCategory = forwardRef((props, ref) => {
  const classes = sectionCategoryStyles();

  const { categories, onClickPrevButton } = props;

  const storedData = useSelector(listenDataStore);
  const [menuTitle, menuTitleSet] = useState("");

  useEffect(() => {
    if (storedData) {
      if (storedData.global) {
        menuTitleSet(storedData.global.menuTitle);
      }
    }
  }, [storedData]);

  return (
    <div
      className={clsx(classes.section_category__root)}
      id="section-category"
      ref={ref}
    >
      <SectionTitle onClickPrevButton={onClickPrevButton}>
        {menuTitle}
      </SectionTitle>
      <Menu compact className={classes.section_category__menu}>
        {categories &&
          categories.map((category) => (
            <div
              key={`menuItem-${category.id}`}
              className={`menuItem-${category.id}`}
            >
              <MenuItem variant="category" id={category.id}>
                {category.title}
              </MenuItem>
            </div>
          ))}
      </Menu>
    </div>
  );
});
