import { useEffect } from "react";

import { DesktopHome } from "features/UI/templates/desktop/home/DesktopHome";
import { TabletHome } from "features/UI/templates/tablet/home/TabletHome";
import { SmartphoneHome } from "features/UI/templates/smartphone/home/SmartphoneHome";

import { useMediaQuery } from "@mui/material";
import theme from "mui/theme";

import { homeStyles } from "./Home.styles";

/*
  You were testing to see if you fixed the landing page rendering when you entered
  the website through a link
*/ 
export function Home() {
  const classes = homeStyles();

  const smartphoneScreen = useMediaQuery(theme.breakpoints.only("smartphone"));
  const tabletScreen = useMediaQuery(theme.breakpoints.only("tablet"));
  const desktopScreen = useMediaQuery(theme.breakpoints.only("desktop"));

  return (
    <div className={classes.home__root}>
      {desktopScreen && <DesktopHome />}
      {tabletScreen && <TabletHome />}
      {smartphoneScreen && <SmartphoneHome />}
    </div>
  );
}
