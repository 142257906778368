export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <path
        stroke="#525252"
        strokeMiterlimit="2.368"
        strokeWidth="0.75"
        d="M1 1l8.217 8.22M1 9.22L9.217 1"
      ></path>
    </svg>
  );
};
