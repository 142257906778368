import { useEffect, useState } from "react";

import { carouselStyles } from "./Carousel.styles";

import THEME from "mui/theme-tokens.json";
import { getStrapiMedia } from "features/utils/strapi/media";
const token_carouselImageInTimeout =
  THEME.transitions.fade.carouselImageInTimeout;
const token_carouselImageOffTimeout =
  THEME.transitions.fade.carouselImageOffTimeout;

export function Carousel(props) {
  const classes = carouselStyles();

  const images = props.images.data || [];

  const [breath, breathSet] = useState(false);
  const [count, countSet] = useState(0);

  const [currentImageIndex, currentImageIndexSet] = useState(0);
  const [fadeTimeout, fadeTimeoutSet] = useState(token_carouselImageInTimeout);

  useEffect(() => {
    const imageElement = document.getElementById(`img-${currentImageIndex}`);
    if (imageElement) {
      imageElement.style.opacity = breath ? 0 : 1;
    }

    controlImageChange();

    if (breath) {
      fadeTimeoutSet(token_carouselImageInTimeout);
    } else {
      fadeTimeoutSet(token_carouselImageOffTimeout);
    }

    let timer = setTimeout(() => breathSet(!breath), fadeTimeout);

    return () => {
      clearTimeout(timer);
    };
  }, [breath]);

  const controlImageChange = () => {
    if (count === 0) {
      countSet(count + 1);
    } else {
      if (images.length === 0) return;

      if (currentImageIndex < images.length) {
        currentImageIndexSet(currentImageIndex + 1);
      } else {
        const nextButtonElement = document.getElementById("next-button");
        if (nextButtonElement) {
          nextButtonElement.click();
        }
      }
      countSet(0);
    }

    return;
  };

  return (
    <div className={classes.carousel__root}>
      {images.map((image, index) => {
        return (
          <div
            className={classes.carousel__image}
            key={`img-${index}`}
            id={`img-${index}`}
            alt="ilustrative"
          >
            <img src={getStrapiMedia(image)} />
          </div>
        );
      })}
    </div>
  );
}
