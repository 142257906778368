import { updateState } from 'features/event-driver/broker/eventBrokerSlice';
import { updateStore } from 'features/event-driver/store/dataStoreSlice';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllLocaleEntries } from '../strapi/requests';

export const useUpdateStoreWithCmsData = (lang) => {
  const dispatch = useDispatch();
  const language = lang === 'de' ? 'de-DE' : lang;

  const [readyToUpdate, readyToUpdateSet] = useState(false);
  const [dataForTheStore, dataForTheStoreSet] = useState();

  
  async function loadCmsData() {
    dispatch(updateState('loading'));

    await getAllLocaleEntries(language).then((res) => {
      dataForTheStoreSet(res);
      readyToUpdateSet(!readyToUpdate);
      dispatch(updateState('idle'));
    });
  }
  
  useEffect(() => {
    if (language) {
      loadCmsData();
    }
  }, [language]);

  useEffect(() => {
    dispatch(
      updateStore({
        storedData: dataForTheStore
      })
    );
  }, [readyToUpdate]);

  return;
};
