import { makeStyles } from "@mui/styles";
import theme from "mui/theme";

export const appStyles = makeStyles(() => ({
  '@global': {
    [theme.breakpoints.only("smartphone")]: {
      body: {
        overflow: 'hidden',
      },
    }
  },
  app__root: {
    backgroundColor: theme.palette.secondary.light,

    maxHeight: "100vh",

    maxWidth: "100vw",
    // overflowX: "hidden",
    // overflowY: "visible",
    position: "relative",

    // transition: 'opacity 2s ease-in-out 1s',
    // opacity: '0',

    scrollbarColor: "rgba(0, 0, 0, 0) rgba(0, 0, 0, 0)",

    [theme.breakpoints.only("smartphone")]: {
      overflowY: "hidden",
    },
    // '&[data-fade-in="true"]': {
    //   opacity: '1',
    // }
  },
  blob__container: {
    position: "fixed",
    zIndex: 99,
    
    [theme.breakpoints.only('smartphone')]: {
      bottom: 72,
      right: 14,
    },
    [theme.breakpoints.only('tablet')]: {
      bottom: 157,
      right: 140,
    },
    [theme.breakpoints.only('desktop')]: {
      bottom: 120,
      left: 120,
    }
  }
}));
