import { makeStyles } from "@mui/styles";
import theme from "mui/theme";

export const arrowStyles = makeStyles(() => ({
  arrow_root: {
    cursor: "pointer",
    transition: "all ease 0.2s",
    

    "& svg": {
      rotate: "180deg",
    },
    [theme.breakpoints.only('desktop')]: {    
      "&:hover": {
        "& path": {
          strokeOpacity: 1,
          fillOpacity: 1
        },
      },
    }
  },
  arrow_previous: {
    "& svg": {
      rotate: "unset",
    },
  },
}));
