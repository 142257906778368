import { makeStyles } from "@mui/styles";
import theme from "../../../../mui/theme";

export const footerStyles = makeStyles(() => ({
  footer_root: {
    position: "fixed",
    zIndex: 3,
    left: 30,
    bottom: 2,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,

    [theme.breakpoints.only('tablet')]: {
      left: 15
    }
  },
}));
