import { makeStyles } from '@mui/styles';

export const useAppOverlayStyles = makeStyles((theme) => ({
  app_overlay: {
    position: 'fixed',
    inset: '0',
    zIndex: '3',

    pointerEvents: 'none',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& p': {
      width: '44px',
      textAlign: 'right'
    }
  },
  ao__loading_counter: {
    opacity: '1',

    '&[data-fade-out="true"]': {
      opacity: '0'
    }
  }
}));
