import { forwardRef, useEffect, useState } from "react";

import { ContentWrapper as ContentWrapperStyles } from "features/UI/molecules/content/wrapper/text-block/ContentWrapper";

import { SectionTitle } from "../title/SectionTitle";

import { Slide, Typography } from "@mui/material";
import THEME from "mui/theme-tokens.json";
import { sectionContentStyles } from "./SectionContent.styles";

const token_textsTimeout = THEME.transitions.slide.textsTimeout;
const slideTimeout = token_textsTimeout;

export const SectionContent = forwardRef((props, ref) => {
  const classes = sectionContentStyles();

  const [sectionTitle, sectionTitleSet] = useState("");
  const [sectionSubTitle, sectionSubTitleSet] = useState("");

  const {
    categories,
    selectedCategory,
    subcategories,
    selectedSubcategory,
    content,
    onClickPrevButton,
  } = props;

  useEffect(() => {
    if (selectedCategory && selectedSubcategory) {
      buildSectionTitle();
    }
  }, [selectedCategory, selectedSubcategory]);

  function buildSectionTitle() {
    const categoryName =
      categories?.find((category) => category.id === selectedCategory) || {};

    if (!categoryName) return;

    const subcategoryName =
      subcategories?.find(
        (subcategory) => subcategory.id === selectedSubcategory
      ) || {};

    sectionSubTitleSet(subcategoryName.title);

    let title = "";
    if (categoryName && subcategoryName) {
      title = `${categoryName.title}`;
    }

    sectionTitleSet(title);
    return;
  }

  return (
    <Slide direction="left" in timeout={slideTimeout}>
      <div className={classes.section_content__root}>
        <SectionTitle onClickPrevButton={onClickPrevButton}>
          {sectionTitle}
        </SectionTitle>
        <Typography variant="h1" className={classes.section_content__title}>
          {sectionSubTitle}
        </Typography>
        <ContentWrapperStyles smartphone>
          <div
            id={`content-${content?.id}`}
            dangerouslySetInnerHTML={{
              __html: content?.content,
            }}
          ></div>
        </ContentWrapperStyles>
      </div>
    </Slide>
  );
});
