import { makeStyles } from "@mui/styles";

export const previousStyles = makeStyles(() => ({
  previous_root: {
    margin: "22px 3px 0px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
}));
