import { makeStyles } from "@mui/styles";

export const desktopContentStyles = makeStyles(() => ({
  desktop_content__root: {
    height: "100%",
    width: "100vw",
  },

  desktop_content__logo_container: {
    position: "fixed",
    zIndex: 3,
    top: 0,
    left: 0,
  },

  desktop_content__main_container: {
    width: "100%",
    minHeight: "70vh",
    padding: "0px 17vw",
    display: "flex",
  },

  desktop_content__menu_grid_column: {
    position: "relative",
    width: "fit-content",
    wordBreak: "break-word",
  },

  desktop_content__left_column: {
    display: "flex",
    position: "fixed",
    top: 30,
  },
}));
