import { makeStyles } from '@mui/styles';

export const useCookiesBannerStyles = makeStyles((theme) => ({
  cookies_banner: {
    position: 'fixed',
    inset: 0,
    zIndex: 99,

    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column'
  },
  cb_backdrop: {
    width: '100%',

    flexGrow: '1',

    backgroundColor: 'rgba(82, 82, 82, 0.07)',

    cursor: 'pointer'
  },
  cb_content: {
    width: '100%',
    padding: '2rem',

    backgroundColor: '#f1ebe7',

    display: 'flex',
    alignItems: 'center',

    position: 'relative'
  },
  cb_c__text: {
    width: '100%',

    lineHeight: '16px !important',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cb_button: {
    padding: '0',
    
    marginLeft: '2rem',
    cursor: 'pointer',

    background: 'none',
    border: 'none',

    '& svg': {
      '& path': {
        transition: 'all .2s ease-in-out'
      }
    },
    [theme.breakpoints.up('desktop')]: {
      marginLeft: 0,

      position: 'absolute',
      right: 30
    },
    '&:hover': {
      '& svg': {
        '& path': {
          stroke: theme.palette.primary.main
        }
      }
    }
  }
}));
