import {
  listenStoredEvents,
  updateSelectedCategory,
  updateSelectedSubcategory,
} from "features/event-driver/broker/eventBrokerSlice";
import { selectCurrentPage } from "features/event-driver/navigation/navigationSlice";
import { listenDataStore } from "features/event-driver/store/dataStoreSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateStoreWithCmsData } from "../custom-hooks/useUpdateStoreWithCmsData";
import { useRouterFinder } from "./useRouterFinder";

export const useRouterSimulation = (isLoading) => {
  const dispatch = useDispatch();

  const routed = useRouterFinder();
  const currentPage = useSelector(selectCurrentPage);
  const storedEvents = useSelector(listenStoredEvents);
  const selectedLanguage = storedEvents.selectedLanguage;
  const selectedCategory = storedEvents.selectedCategory;
  const selectedSubcategory = storedEvents.selectedSubcategory;

  useUpdateStoreWithCmsData(selectedLanguage);

  const storedData = useSelector(listenDataStore);
  const uncategorizedContent =
    (storedData && storedData.uncategorizedContent) || [];
  const categories = (storedData && storedData.categories) || [];
  const subcategories = (storedData && storedData.subcategories) || [];

  useEffect(() => {
    if (currentPage === "content") {
      if (routed.category !== "" && categories) {
        const category = categories.find((cat) => cat.slug === routed.category);
        if (category) {
          dispatch(updateSelectedCategory(category.id));
          if (routed.subcategory !== "" && subcategories) {
            const subcategory = subcategories.find(
              (cat) => cat.slug === routed.subcategory
            );
            if (subcategory) {
              dispatch(updateSelectedSubcategory(subcategory.id));
            }
          }
        }
      }
    }
  }, [currentPage, categories, subcategories]);

  const [path, pathSet] = useState("");

  useEffect(() => {
    const lang = selectedLanguage.slice(0, 2);

    if (currentPage === "home") {
      pathSet(`${selectedLanguage}`);
    } else {
      pathFinder(lang);
    }
  }, [
    currentPage,
    selectedLanguage,
    selectedCategory,
    selectedSubcategory,
    uncategorizedContent,
  ]);

  useEffect(() => {
    if (window.location.pathname !== '/' && path !== '' && !isLoading) {
      updateUrl(path);
    }
  }, [path, isLoading]);

  function pathFinder(lang) {
    if (selectedCategory !== "") {
      const category = categories?.find(
        (category) => category.id === selectedCategory
      );

      if (selectedSubcategory !== "") {
        const subcategory = subcategories?.find(
          (subcategory) => subcategory.id === selectedSubcategory
        );

        pathSet(`${lang}/${category.slug}/${subcategory.slug}`);
      } else {
        pathSet(`${lang}/${category.slug}`);
      }
    } else {
      pathSet(
        `${lang}/${uncategorizedContent.slug ? uncategorizedContent.slug : ""}`
      );
    }
  }
};

export const updateUrl = (newPath) => {
  let stateObj = { id: randomInt() };

  window.history.pushState(stateObj, "Page", `/${newPath}`);
};

function randomInt() {
  const min = 1;
  const max = 99;
  return Math.floor(Math.random() * (max - min + 1) + min);
}
