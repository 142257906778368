import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { listenDataStore } from "features/event-driver/store/dataStoreSlice";
import { useSelector } from "react-redux";
import { footerStyles } from "./Footer.styles";

export function Footer() {
  const classes = footerStyles();

  const storedData = useSelector(listenDataStore);
  const [footerContent, footerContentSet] = useState("");

  useEffect(() => {
    if (storedData) {
      if (storedData.global) {
        footerContentSet(storedData.global.footerContent);
      }
    }
  }, [storedData]);

  return (
    <div className={classes.footer_root}>
      <Typography variant="caption">{footerContent}</Typography>
    </div>
  );
}
