import { useState } from "react";

import { Footer } from "features/UI/organisms/footer/Footer";
import { Navigation } from "features/UI/organisms/navigation/Navigation";

import { Next } from "features/UI/molecules/navigation/buttons/next/Next";

import { Blob } from "features/UI/atoms/svgs/blob/Blob";
// import { Logo } from "features/UI/atoms/svgs/logo/Logo";

import { Fade } from "@mui/material";
import theme from "mui/theme";

import { PAGES } from "features/utils/constants/pages";

import { ReactComponent as CenteredImage } from "design-spec/assets/svg/Home.svg";

import { desktopHomeStyles } from "./DesktopHome.styles";
import { useDispatch } from "react-redux";
import { updateCurrentPage } from "features/event-driver/navigation/navigationSlice";

const timeout = theme.transitions.fade.imagesTimeout;

export function DesktopHome() {
  const classes = desktopHomeStyles();
  const dispatch = useDispatch();

  const [secondFade, secondFadeSet] = useState(false);

  const startSecondFade = () => {
    secondFadeSet(true);
  };

  function onClickNavButton() {
    dispatch(updateCurrentPage("content"));
    return;
  }

  return (
    <>
      <div className={classes.desktop_home__root}>
        <Fade
          in
          timeout={timeout}
          addEndListener={(node) => {
            node.addEventListener(
              "transitionend",
              () => {
                startSecondFade();
              },
              false
            );
          }}
        >
          <div className={classes.desktop_home__logo_container}>
            {/* <Logo /> */}
          </div>
        </Fade>

        <CenteredImage className={classes.desktop_home__image} />

        <Fade in={secondFade} timeout={timeout}>
          <div>
            <Navigation>
              <Next onClick={onClickNavButton} />
            </Navigation>
          </div>
        </Fade>
      </div>

      <Footer />
    </>
  );
}
