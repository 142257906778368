import THEME_TOKENS from "mui/theme-tokens.json";

import { makeStyles } from "@mui/styles";

export const contentWrapperStyles = makeStyles((theme) => ({
  content_wrapper__root: {
    paddingLeft: 15,

    "& p": {
      marginTop: "unset",
      marginBottom: 10,

      fontFamily: "AvenirNextMedium",
      fontStyle: theme.typography.allVariants.fontStyle,
      fontWeight: theme.typography.allVariants.fontWeight,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      letterSpacing: theme.typography.body1.letterSpacing,
      opacity: theme.typography.body1.opacity,

      maxWidth: THEME_TOKENS.spacing.contentColumnMaxWidth.desktop - 50,

      "& a": {
        textDecoration:
          THEME_TOKENS.typography.allVariants.links.textDecoration,
        color: theme.palette.primary.dark,
        transition: `color ease 0.4s`,
        "&:hover": {
          color: theme.palette.primary.hoveredLink,
        },
      },
    },

    "& h1": {
      marginTop: 35,
      marginBottom: 11,

      fontFamily: "AvenirNextMedium",
      fontStyle: theme.typography.allVariants.fontStyle,
      fontWeight: theme.typography.allVariants.fontWeight,
      fontSize: theme.typography.h1.fontSize,
      lineHeight: theme.typography.h1.lineHeight,
      letterSpacing: theme.typography.subtitle1.letterSpacing,

      opacity: theme.typography.subtitle1.opacity,

      maxWidth: 260,
    },

    "& h3": {
      margin: "unset",
      marginBottom: 16,

      fontFamily: "AvenirNextMedium",
      fontStyle: theme.typography.allVariants.fontStyle,
      fontWeight: theme.typography.allVariants.fontWeight,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      letterSpacing: theme.typography.body1.letterSpacing,
      opacity: theme.typography.body1.opacity,

      maxWidth: THEME_TOKENS.spacing.contentColumnMaxWidth.desktop - 50,
    },

    "& h4": {
      marginTop: 50,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.03em",
      fontStyle: "normal",
      maxWidth: 295,
      color: "rgba(54, 54, 54, 0.25)",
    },

    "& ul": {
      marginTop: "unset",
      marginBottom: 35,
      paddingLeft: 0,
      maxWidth: 430,

      listStyleType: "none",
      "& li": {
        backgroundImage: "url(/assets/svgs/Bulletpoint.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0 9px",
        paddingLeft:
          THEME_TOKENS.typography.allVariants.lists.spaceBetweenBulletAndText,

        marginBottom: 5,
        opacity: theme.typography.body1.opacity,
      },
    },

    // "& img:first-child": {
    //   marginTop: 35,
    // },

    "& img": {
      minWidth: THEME_TOKENS.spacing.contentTextMaxWidth.desktop,
      width: "35vw",
      height: "auto",
      marginBottom: 15,
    },

    "& .contact_caption_text": {
      marginTop: 50,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.03em",
      fontStyle: "normal",
      maxWidth: 295,
      color: "rgba(54, 54, 54, 0.25)",
    },
  },
  content_wrapper__smartphone: {
    padding: "0 1rem 33vh 1rem",
    overflowX: "hidden",

    "& ul": {
      paddingRight: "4rem",
    },
    "& img": {
      minWidth: "100%",
    },
    [theme.breakpoints.only("tablet")]: {
      paddingLeft: "0",
    },
  },
}));
