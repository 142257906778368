import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { listenDataStore } from 'features/event-driver/store/dataStoreSlice';
import {
  listenEventBroker,
  updateSelectedCategory,
  updateSelectedLanguage,
  updateSelectedSubcategory
} from 'features/event-driver/broker/eventBrokerSlice';

import { Typography } from '@mui/material';

import { useRouterFinder } from 'features/utils/routes/useRouterFinder';

import { LANGUAGES, rearrangeLangArray } from './utils/languageUtils';

import { languageSelectorStyles } from './LanguageSelector.styles';

const initialLang = process.env.REACT_APP_STRAPI_DEFAULT_LOCALE || 'de';

export function LanguageSelector({ fadeIn }) {
  const classes = languageSelectorStyles();

  const storedData = useSelector(listenDataStore);
  const { storedEvents } = useSelector(listenEventBroker);

  const dispatch = useDispatch();

  const [languages, languagesSet] = useState(LANGUAGES);
  const [activeLang, activeLangSet] = useState('');
  const [renderControl, renderControlSet] = useState(false);

  const routedLang = useRouterFinder(renderControl);

  const [stateControl, stateControlSet] = useState('initial');
  const [inputClass, inputClassSet] = useState(
    classes.language_selector__state_initial
  );

  function classConstructor() {
    if (stateControl === 'select') {
      inputClassSet(classes.language_selector__state_select);
    } else {
      inputClassSet(classes.language_selector__state_initial);
    }
    return;
  }

  useEffect(() => {
    classConstructor();
  }, [stateControl]);

  useEffect(() => {
    if (routedLang.section !== '') {
      if (languages.includes(routedLang.language)) {
        handleLanguageSelectionOnInit(routedLang.language);
      } else {
        console.log("Language not found! Setting 'DE' as default");
        handleLanguageSelectionOnInit(initialLang);
      }
    } else if (!renderControl) {
      renderControlSet(true);
    }
  }, [routedLang, renderControl]);

  const handleLanguageSelectionOnInit = (lang) => {
    languagesSet(rearrangeLangArray(lang));
    activeLangSet(lang);

    dispatch(updateSelectedLanguage(lang));
  };

  const returnUrl = (lang) => {
    const activeCategoryId = storedEvents.selectedCategory;
    const activeSubcategoryId = storedEvents.selectedSubcategory;

    if (activeCategoryId || activeSubcategoryId) {
      const activeCategoryProps =
        storedData.categories.find(
          (category) => category.id === activeCategoryId
        ) || {};

      const activeSubcategoryProps =
        storedData.subcategories.find(
          (subcategory) => subcategory.id === activeSubcategoryId
        ) || {};

      const categorySlug = activeCategoryProps[`alias_slug_${lang}`] || '';
      const subcategorySlug =
        activeSubcategoryProps[`alias_slug_${lang}`] || '';

      return `/${lang}/${categorySlug}/${subcategorySlug}`;
    } else {
      const isOnIntroPage =
        window.location.pathname.split('/').slice(1).length === 2;

      if (isOnIntroPage) {
        const uncategorizedContentProps = storedData.uncategorizedContent;

        return `/${lang}/${uncategorizedContentProps[`alias_slug_${lang}`]}`;
      } else {
        return `/${lang}`;
      }
    }
  };

  const handleLanguageClick = (lang) => {
    if (stateControl === 'initial') {
      stateControlSet('select');
    } else if (stateControl === 'select') {
      //Means the user choose a new lang, then proceed to lang change
      if (lang !== activeLang) {
        languagesSet(rearrangeLangArray(lang));
        activeLangSet(lang);
        window.location = returnUrl(lang);
      }

      stateControlSet('initial');
    }
  };

  return (
    <div
      data-fade-in={fadeIn}
      data-open={Boolean(stateControl === 'select')}
      className={classes.language_selector__root}
    >
      <div
        data-open={Boolean(stateControl === 'select')}
        className={classes.ls_background}
      />
      {languages.map((lang, index) => (
        <Typography
          // component="a"
          // href={`/${returnUrl(lang)}`}
          key={`langSelect-${index}`}
          variant="h6Alternative"
          className={inputClass}
          onClick={() => handleLanguageClick(lang)}
        >
          {lang.toUpperCase()}
        </Typography>
      ))}
    </div>
  );
}
