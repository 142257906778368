import { makeStyles } from "@mui/styles";

export const sectionContentStyles = makeStyles((theme) => ({
  section_content__root: {
    // "& h1": {
    //   marginTop: 35,
    //   marginBottom: 11,

    //   fontFamily: "AvenirNextMedium",
    //   fontStyle: theme.typography.allVariants.fontStyle,
    //   fontWeight: theme.typography.allVariants.fontWeight,
    //   fontSize: theme.typography.h1.fontSize,
    //   lineHeight: theme.typography.h1.lineHeight,
    //   letterSpacing: theme.typography.subtitle1.letterSpacing,

    //   opacity: theme.typography.subtitle1.opacity,

    //   maxWidth: 260,
    // },
  },

  section_content__title: {
    padding: "0 1rem",
    marginBottom: "10px !important",
    maxWidth: "80%"
  },

}));
