import { makeStyles } from "@mui/styles";

export const menuStyles = makeStyles((theme) => ({
  menu__root: {
    height: "100%",
    width: 235,

    [theme.breakpoints.only('tablet')]: {
      marginRight: 5
    }
  },

  menu__compact_items: {
    "& div": {
      marginBottom: 6,
    },
  },
}));
