import clsx from "clsx";

import { menuStyles } from "./Menu.styles";

export function Menu({ children, compact = false, className }) {
  const classes = menuStyles();

  return (
    <div
      className={clsx(classes.menu__root,
        compact && classes.menu__compact_items,
        className,
      )}
    >
      {children}
    </div>
  );
}
