import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentPage,
  updateCurrentPage
} from 'features/event-driver/navigation/navigationSlice';

import { Fade } from '@mui/material';

import { Home } from 'features/UI/pages/home/Home';
import { Content } from 'features/UI/pages/content/Content';

import theme from 'mui/theme';
import { Blob } from 'features/UI/atoms/svgs/blob/Blob';

// import { Logo } from 'features/UI/logo/Logo';
import { LanguageSelector } from 'features/UI/molecules/language/LanguageSelector';
import { listenDataStore } from 'features/event-driver/store/dataStoreSlice';
import { listenEventBroker } from 'features/event-driver/broker/eventBrokerSlice';

import {
  updateUrl,
  useRouterSimulation
} from 'features/utils/routes/useRouterSimulation';
import { useRouterFinder } from 'features/utils/routes/useRouterFinder';
import { AppOverlay } from 'features/UI/app-overlay/AppOverlay';
import { CookiesBanner } from 'features/UI/cookies-banner/CookiesBanner';

import { appStyles } from './App.styles';

const timeout = theme.transitions.fade.imagesTimeout;

function App() {
  const classes = appStyles();

  const dispatch = useDispatch();

  // useUpdateStoreWithCmsData(initialLang);

  const currentPage = useSelector(selectCurrentPage);
  const storedData = useSelector(listenDataStore);

  const [pageRenderer, pageRendererSet] = useState();
  // const [isLoading, setIsLoading] = useState(true);
  const [fadeOutContent, setFadeOutContent] = useState(false);
  const { state: loadingState } = useSelector(listenEventBroker);

  const isLoading = loadingState === 'loading';

  const routed = useRouterFinder(isLoading);
  useRouterSimulation(isLoading);

  const actualPageComponent = () => {
    switch (currentPage) {
      case 'home':
        return <Home />;
      case 'content':
        return <Content />;
      default:
        return <Home />;
    }
  };

  useEffect(() => {
    /*
      Sets the default language as german.
    */
    if (window.location.pathname === '/') {
      updateUrl('de');
    }
  }, []);

  useEffect(() => {
    pageRendererSet(actualPageComponent());
  }, [currentPage, isLoading]);

  const handleUpdateCurrentPage = () => {
    if (routed.section === '') {
      return;
    }

    if (routed.section === 'home') {
      dispatch(updateCurrentPage('home'));
    } else {
      dispatch(updateCurrentPage('content'));
    }
  };

  useEffect(() => {
    handleUpdateCurrentPage();
  }, [routed]);

  useEffect(() => {
    if (currentPage === 'home') {
      document.body.style.overflow = 'hidden';
    }
  }, [currentPage]);

  return (
    <>
      <LanguageSelector fadeIn={!isLoading} />

      {storedData.categories && (
        <Fade in={!isLoading} timeout={timeout}>
          <div id="app-root" className={classes.app__root}>
            {pageRenderer}
            <Fade in timeout={timeout}>
              <div className={classes.blob__container}>
                <Blob />
              </div>
            </Fade>
          </div>
        </Fade>
      )}

      <AppOverlay
        isLoading={isLoading}
        fadeOutContent={fadeOutContent}
        setFadeOutContent={setFadeOutContent}
        storedData={storedData}
      />
      <CookiesBanner />
    </>
  );
}

export { App };
