import { useState, useEffect } from "react";
import { Carousel } from "features/UI/organisms/carousel/Carousel";
import { Navigation } from "features/UI/organisms/navigation/Navigation";

import { Next } from "features/UI/molecules/navigation/buttons/next/Next";

// import { Logo } from "features/UI/atoms/svgs/logo/Logo";

import { Fade } from "@mui/material";
import theme from "mui/theme";

import { moveTouch, startTouch } from "features/utils/events/touchEvents";

import { smartphoneHomeStyles } from "./SmartphoneHome.styles";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPage } from "features/event-driver/navigation/navigationSlice";
import { listenDataStore } from "features/event-driver/store/dataStoreSlice";

const timeout = theme.transitions.fade.imagesTimeout;

export function SmartphoneHome() {
  const classes = smartphoneHomeStyles();
  const dispatch = useDispatch();

  function onClickNavButton() {
    dispatch(updateCurrentPage("content"));
    return;
  }
  
  const storedData = useSelector(listenDataStore);
  const [carouselImages, carouselImagesSet] = useState([]);

  useEffect(() => {
    if (storedData) {
      if (storedData.global) {
        carouselImagesSet(storedData.homepage.carousel.images);
      }
    }
  }, [storedData]);

  return (
    <>
      <Fade in timeout={timeout}>
        <div className={classes.smartphone_home__navigation_container}>
          <Navigation>
            <Next onClick={onClickNavButton} />
          </Navigation>
        </div>
      </Fade>
      <div
        id="smartphone_home__root"
        className={classes.smartphone_home__root}
        onTouchStart={(e) => startTouch(e.targetTouches[0])}
        onTouchMove={(e) => moveTouch(e.changedTouches[0])}
      >
        {/* <div className={classes.smartphone_home__logo_container}>
          <Logo />
        </div> */}

        <Carousel images={carouselImages} />
      </div>
    </>
  );
}
