import { useEffect, useState } from "react";
import {  useSelector } from "react-redux";

import { listenDataStore } from "features/event-driver/store/dataStoreSlice";
import { Arrow } from "features/UI/atoms/navigation/arrow/Arrow";

import { Typography } from "@mui/material";

import { nextStyles } from "./Next.styles";

export function Next(props) {
  const classes = nextStyles();

  const { whitoutText, onClick } = props;

  const storedData = useSelector(listenDataStore);
  const [buttonContent, buttonContentSet] = useState("");

  useEffect(() => {
    if (storedData) {
      if (storedData.global) {
        buttonContentSet(storedData.global.navigationButton);
      }
    }
  }, [storedData]);

  return (
    //Don't remove id="next-button" or touch funcions will break
    <div className={classes.next_root}>
      <div onClick={onClick} id="next-button">
        <Arrow direction="next" />

      {!whitoutText && (
        <Typography variant="caption" className={classes.next_text}>
          {buttonContent}
        </Typography>
      )}
      </div>
    </div>
  );
}
