// export const LANGUAGES = [
//   {
//     cod: "fr",
//     short: "FR",
//   },
//   {
//     cod: "en",
//     short: "EN",
//   },
//   {
//     cod: "de-DE",
//     short: "DE",
//   },
// ];
export const LANGUAGES = ['fr', 'en', 'de'];

export function rearrangeLangArray(lang) {
  const initial = LANGUAGES;
  const temp = initial.filter((el) => el !== lang);
  temp.push(lang);

  return temp;
}
