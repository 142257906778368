import { Arrow } from "features/UI/atoms/navigation/arrow/Arrow";

import { previousStyles } from "./Previous.styles";

export function Previous(props) {
  const classes = previousStyles();

  const { onClick } = props;

  return (
    //Don't remove id="previous-button" or touch funcions will break
    <div
      className={classes.previous_root}
      onClick={onClick}
      id="previous-button"
    >
      <Arrow direction="previous" />
    </div>
  );
}
